import * as React from 'react'
import { Typography, Toolbar, Box, AppBar  } from '@mui/material'
import Profile from './Profile'


export default function NavMenu() {

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            > Match Maker
          </Typography>
          <Profile />
        </Toolbar>
      </AppBar>
    </Box>
  )
}
