import React, { useEffect, useState } from 'react';
import './App.css';
import { Container } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ErrorPage from './components/ErrorPage';
import Home from './components/Home';
import NavMenu from './components/NavMenu';
import Loading from './components/Loading';
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import axios from 'axios'

const App = () => {
  const [ interceptorAdded, setInterceptorAdded ] = useState(false)
  const { getAccessTokenSilently } = useAuth0();


  useEffect(() => {
    axios.interceptors.request.use(async config => {
      if (config !== undefined && config.headers !== undefined) {
        const token = await getAccessTokenSilently();
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    setInterceptorAdded(true);
  }, [getAccessTokenSilently]);

  return (
    interceptorAdded ?
    <Container maxWidth="lg">
      <NavMenu />
      <br />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </Container> 
    :
     null
  );
}

export default withAuthenticationRequired(App, {
  onRedirecting: () => <Loading />
});
