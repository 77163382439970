import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Avatar, IconButton, Menu, MenuItem, styled, Tooltip } from '@mui/material'

const StyledMenu = styled(Menu) ({
    menu: {
        "& .MuiMenu-root": {
            backgroundColor: 'red'
        },
        "& .MuiMenu-paper": {
            backgroundColor: 'blue'
        },
        "& .MuiPaper-root": {
            backgroundColor: 'yellow',
        }
    }
})

export default function Profile() {
    const { user, isAuthenticated, logout } = useAuth0()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    if (isAuthenticated) {
        return (
            <div>
            <Tooltip title="Open profile">
                <IconButton onClick={handleMenu}>
                    <Avatar alt={user?.name} src={user?.picture} />
                </IconButton>
            </Tooltip>
            <StyledMenu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
            <MenuItem onClick={handleClose}>{user?.profile}</MenuItem>
            <MenuItem onClick={() => logout({returnTo: window.location.origin})}>Logout</MenuItem>
            </StyledMenu>
        </div>
    )} else return null
}