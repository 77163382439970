import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider } from '@auth0/auth0-react'
import settings from './infrastructure/settings'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <Auth0Provider
    domain={settings.auth0Domain}
    clientId={settings.auth0ClientId}
    redirectUri={window.location.origin}
    organization={settings.auth0Organization}
    audience={settings.auth0Audience}
    scope="venue-aliases-ui:admin venue-aliases-ui:reader"
  >
    <App /> 
  </Auth0Provider>
)

reportWebVitals()
