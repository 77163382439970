import axios from 'axios'
import { useEffect, useState } from 'react'
import endpoints from '../infrastructure/endpoints'
import { Sport } from '../interfaces/Sport'
import { Venue } from '../interfaces/Venue'

export function useFixturesApi() {
    const [venues, setVenues] = useState<Venue[]>([])
    const [sports, setSports] = useState<Sport[]>([])

    const getSports = async () => {
      try {
        const { data } = await axios.get(endpoints.sports)
        setSports(data)
      } catch (error) {
        setSports([])
      }
    }
      
    const getVenues = async () => {
      try {
        const { data } = await axios.get(endpoints.venues)
        setVenues(data)
      } catch(error) {
        setVenues([])
      }
    }

    useEffect(() => {
        getSports()
        getVenues()
    }, [])

  return {
    venues,
    sports
  }
}
