const getSetting = (settingName: string) => {
    const fullSettingName = `REACT_APP_${settingName}`;
    const value = process.env[fullSettingName];
    if (value === undefined) {
        throw Error(`Setting ${fullSettingName} does not exist`);
    }

    return value;
}

const settings = {
    auth0Domain: getSetting("AUTH0_DOMAIN"),
    auth0Audience: getSetting("AUTH0_AUDIENCE"),
    auth0ClientId: getSetting("AUTH0_CLIENT_ID"),
    auth0Organization: getSetting("AUTH0_ORGANIZATION"),
    apiBasePath: getSetting("API_BASE_PATH")
}

export default settings;