import React, { useRef } from 'react'
import { Container, Grid } from '@mui/material'
import GridData from './GridData'
import CreateForm from './CreateForm'

export default function Home() {

  const gridRef = useRef<any>(null)

  const onVenueAliasCreated = (data:any) => {
    gridRef.current.addVenueAlias(data)
  }
  
  return (
    <Container maxWidth="lg">
      <Grid item xs={6}>
       <div style={{ display:'inline-block', float:'right' }}>
        <CreateForm onVenueAliasCreated={onVenueAliasCreated} />
        </div>
        <GridData ref={gridRef} />
      </Grid>
      <br/>
    </Container>
  )
}