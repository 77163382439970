import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'

export default function Searchbar (props:any) {

  const [query, setQuery] = useState<string>('')

  useEffect(() => {
    props.filterData(query)
  }, [query])
  
  return (
    <TextField
        disabled={props.disabled}
        size="small"
        variant="outlined"
        placeholder="Search..."
        type='text'
        value={query}
        onChange={(e) => setQuery(e.target.value.toLowerCase())}
      />
  )
}