import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import endpoints from '../infrastructure/endpoints';
import DialogEdit from './DialogEdit';
import DialogDelete from './DialogDelete';
import Searchbar from './Searchbar';
import { GetVenueAliasRequest } from '../interfaces/GetVenueAliasRequest';
import { VenueAlias } from '../interfaces/VenueAlias';
import { useFixturesApi } from '../hooks/useFixturesApi';

function GridData(props:any, ref:any) {

  const gridRef = useRef<AgGridReact>(null)

  const [disabledSearch, setDisabledSearch]  = useState(true)

  useImperativeHandle(ref, () => ({
    addVenueAlias: (data:any) => {
      gridRef.current?.api.applyTransaction({
        add: [data]
      })
    }
  }))

  const onVenueAliasDeleted = (id:any) => {
    const node = gridRef.current?.api.getRowNode(id)
    gridRef.current?.api.applyTransaction({
      remove: [node!.data]
    })
  }

  const onVenueAliasUpdated = (data:any) => {
    gridRef.current?.api.applyTransaction({
      update: [data]
    })
    const node = gridRef.current?.api.getRowNode(data.id)
    node?.resetQuickFilterAggregateText()
  }

  const { venues, sports } = useFixturesApi()
  const [cursor, setCursor] = useState(0)
  const [allAlisesLoadingStarted, setAllAlisesLoadingStarted] = useState(false)
  const [initialData, setInitialData] = useState<VenueAlias[]>()
  
  const [limit, setLimit] = useState(200)
  
  const requestData = async (currentCursor: number) : Promise<VenueAlias[]> => {
    const model: GetVenueAliasRequest = {
      limit: limit, 
      cursor: currentCursor
    }
    
    const res = await axios.get(endpoints.venueAliases, { params: model })

    return res.data;
  }

  const handleScroll = (params:any) => {
    if (params.api.getFirstDisplayedRow() >= 100){
      loadAllVenueAliases();
    }
  }

  useEffect(() => {
    requestData(0)
      .then(data => { 
        setInitialData(data)
        setDisabledSearch(false)
        setLimit(1000)
        setCursor(getLastId(data))
      })
  }, [])

  const loadAllVenueAliases = async () => {
    if(allAlisesLoadingStarted) {
      return;
    }

    setAllAlisesLoadingStarted(true);

    var data = await requestData(cursor);
    addDataToGrid(data);
    var currentCursor = getLastId(data);
  
    while(data.length >= limit) {      
      data = await requestData(currentCursor);
      addDataToGrid(data);      
      currentCursor = getLastId(data);
    }
  }

  const addDataToGrid = (data: VenueAlias[]) => {
    gridRef.current?.api.applyTransaction({
      add: data
    });
  }

  const getLastId = (data: VenueAlias[]) => {
    return data[data.length-1].id;
  }

  const search = async (query:string) => {
    if(query){
      loadAllVenueAliases()
    }

    gridRef.current?.api?.setQuickFilter(query)
  }

  const EditButton = {
    cellRenderer:(params:any) => 
    <div>
      <DialogEdit onVenueAliasUpdated={onVenueAliasUpdated} model={params.data} />
    </div>,
    sortable: false,  
    filter: false,
    width: 100
  }

  const DeleteButton = {
    cellRenderer:(params:any) => 
    <div>
      <DialogDelete onVenueAliasDeleted={onVenueAliasDeleted} model={params.data} />
    </div>,
    sortable: false,  
    filter: false,
    width: 120
  }
    
  const columnDefs = [
    { headerName: "Id", field: 'id', width:80 },
    { headerName: "Venue id", field: 'venueId', width:120 },
    { headerName: "Venue", field: 'venueName', valueGetter: (params:any) => venues.find(v=>v.id === params?.data?.venueId)?.name, width:150 },
    { headerName: "Alias", field: 'aliasName', width:350, flex: 1 },
    { headerName: "Sport", field: 'sportName', valueGetter: (params:any) => sports.find(s=>s.id === params?.data?.sportId)?.name, width:150 },
    EditButton,
    DeleteButton
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true
  }

  return (
    <div >
      <Searchbar disabled={disabledSearch} filterData={search}/>
      <div className="ag-theme-material" style={{ height: window.innerHeight - 150, width: "100%"}}>
        <AgGridReact
          ref={gridRef}
          getRowId={params => params.data.id}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={initialData}
          enableCellTextSelection={true}
          suppressCellFocus={true}
          suppressDragLeaveHidesColumns={true}
          suppressScrollOnNewData={true}
          cacheQuickFilter={true}
          onBodyScrollEnd={handleScroll}
          onFilterOpened={loadAllVenueAliases}>
        </AgGridReact>
      </div>
    </div>
  )
}

export default forwardRef(GridData)