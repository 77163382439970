import { Button, Dialog, DialogActions, DialogContent, FormControl, TextField } from '@mui/material'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import axios from 'axios'
import React from 'react'
import { useForm } from '../hooks/useForm'
import { UpdateVenueAliasRequest } from '../interfaces/UpdateVenueAliasRequest'
import endpoints from '../infrastructure/endpoints'

export default function DialogEdit(props:any) {

  const { handleClose, updateAliasName, model, handleOpen, open, } = useForm() 

  const handleUpdate = async (id:number) => {
    if (!model.aliasName) {
      console.log('alias name has not been changed')
      return
    }
    handleClose()
    try {
      const { data } = await axios.put<UpdateVenueAliasRequest>(`${endpoints.venueAliases}/${id}`, model)
      props.onVenueAliasUpdated(data)
    } catch(error){
      console.log(error)
    }
  }
  
  return (
    <div>
      <ModeEditOutlineIcon color='primary' onClick={handleOpen}/>
        <Dialog fullWidth maxWidth="xs" BackdropProps = {{ invisible: true }} open={open}>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                multiline
                minRows={4}
                label='Edit alias name, max 100 characters allowed'
                name="aliasName"
                type='string'
                required
                inputProps={{ maxLength: 100 }}
                defaultValue={props?.model?.aliasName}
                onChange={(e) => updateAliasName(e.target.value)}
                />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={() => handleUpdate(props.model.id)}>Submit</Button>
          </DialogActions>
        </Dialog>
  </div>
  )
}