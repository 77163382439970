import React, { useEffect, useState } from 'react'
import { Autocomplete, autocompleteClasses, Button, createFilterOptions, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Popper, styled, TextField } from '@mui/material'
import { useForm } from '../hooks/useForm'
import { NewVenueAliasRequest } from '../interfaces/NewVenueAliasRequest'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import axios from 'axios'
import endpoints from '../infrastructure/endpoints'
import { useFixturesApi } from '../hooks/useFixturesApi'
import { Venue } from '../interfaces/Venue'
import { VenueAlias } from '../interfaces/VenueAlias'

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
})

export default function CreateForm(props:any) {
  
  const { model, updateAliasName, updateSportId, updateVenueId, handleClose, handleOpen, open } = useForm()
  const { venues, sports } = useFixturesApi()

  
  const [errors, setErrors] = useState('')
  const [query, setQuery] = useState<string>('')
  const [debouncedQuery, setDebouncedQuery] = useState<string>(query)
  
    useEffect(() => {
      const timer = setTimeout(() => setQuery(debouncedQuery), 500)
      return () => clearTimeout(timer)
    }, [debouncedQuery])

    useEffect(() => {
        updateAliasName(query)
    }, [updateAliasName, query])

    const handleCreate = async () => {
      handleClose()
      try {
        const { data } = await axios.post<NewVenueAliasRequest>(endpoints.venueAliases, model)
        props.onVenueAliasCreated(data)
      } catch (error){
        if (error instanceof Error) setErrors(error.message)  
      }
    }

    const venueFilterOptions = createFilterOptions({
      limit: 500,
      stringify: (option:Venue) => option.name
    })

    return (
      <div>
        <Button variant="contained" onClick={() => handleOpen()}>
            <AddCircleOutlineIcon />
            Add Venue Alias
        </Button>
        <Dialog fullWidth open={open}>
        <DialogTitle>Create new venue alias</DialogTitle>
        <DialogContent>
        <FormControl fullWidth>
          <TextField fullWidth  
            variant="outlined"
            margin="dense"
            label='Alias name'
            placeholder="Add alias name..."
            helperText='*max. 100 characters allowed'
            name="aliasName"
            type="string"
            inputProps={{ maxLength : 100 }}
            required
            onChange={(e) => setDebouncedQuery(e.target.value)}
            />
        </FormControl>
        <FormControl fullWidth>
          <Autocomplete fullWidth
            PopperComponent={StyledPopper}
            filterOptions={venueFilterOptions}
            disableListWrap
            autoComplete
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, venue:any) => updateVenueId(venue)}
            options={venues}
            getOptionLabel={venue => venue.name}
            renderOption={(props, venue) => {
                return <li {...props} key={venue.id}>{venue.name}</li>
            }}
            renderInput={(params) => 
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="Type in venue..."
              label="Venue"
              helperText='*only first 500 venues are shown in the dropdown'
              name="venueId"
              required />}
            />
        </FormControl>
        <FormControl fullWidth>
          <Autocomplete fullWidth
            PopperComponent={StyledPopper}
            disableListWrap
            autoComplete
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, sport:any) => updateSportId(sport)}
            options={sports}
            getOptionLabel={sport => sport.name}
            renderOption={(props, sport) => {
              return <li {...props} key={sport.id}>{sport.name}</li>
            }}
            renderInput={(params) => 
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              margin="dense"
              label="Sport"
              placeholder="Type in sport..."
              helperText='*all sports are shown in the dropdown'
              name="sportId"
              required />}
            />
        </FormControl>
        </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleCreate}>Create</Button>
          </DialogActions>
      </Dialog>
    </div>
  )
}


