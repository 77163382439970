import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import axios from 'axios'
import React from 'react'
import { useForm } from '../hooks/useForm'
import endpoints from '../infrastructure/endpoints'

export default function DialogDelete(props:any) {

    const { handleClose, handleOpen, open, } = useForm() 
    
    const handleDelete = async (id:number) => {
      if(!id) {
        console.log('Error selecting entry')
        return
      }
      handleClose()
      try {
       const { data } = await axios.delete(`${endpoints.venueAliases}/${id}`)
       props.onVenueAliasDeleted(data)
      } catch (error){
        console.log(error)
      }
    }

  return (
    <div>        
      <DeleteOutlineIcon color="error" onClick={handleOpen}/>
      <Dialog open={open} BackdropProps = {{ invisible: true }}>
        <DialogContent dividers>
          Are you sure you want to delete this record?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="error" variant='outlined' onClick={() => handleDelete(props.model.id)}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
