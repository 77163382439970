import React, { useState } from 'react'
import { Sport } from '../interfaces/Sport'
import { Venue } from '../interfaces/Venue'

export function useForm() {

    const [model, setModel] = useState<{[key:string]: any}>({})
    const [open, setOpen] = useState(false)

    const updateAliasName = (input:string) => {
        model.aliasName = input
    }

    const updateSportId = (input?:Sport) => {
        model.sportId = Number(input?.id)
    }
 
    const updateVenueId = (input?:Venue) => {
        model.venueId = Number(input?.id)
    }

    const resetForm = () => {
        setModel({})
    }

    const handleClose = () => {
        setOpen(false)
        resetForm()
    }

    const handleOpen = () => {
        setOpen(true)
    }

    return {
        model,
        open, 
        handleClose,
        handleOpen,
        resetForm,
        updateAliasName,
        updateSportId,
        updateVenueId
    }
}
